/* Projet : RollingPizza Admin
*  Script : Provider pour tout traitement des clients.
*  Auteur : Helvitech Dev Team 
*  Date : 17.02.2021
*  ------------------------
*  Description :  Provider qui va faire le traitement entre les fonction présents dans les ".ts" 
*                    et la base de données, dont les scripts sont défini dans "config_bdd.ts".
******************************************************************************************************/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

/*****************************************************************************
 * Importation des scripts des fonctions concernant le traitement de données *
 *  pour les produits.
******************************************************************************/

import {
    LIEN_GET_ALL_EMPLOYES,

    LIEN_GET_EMPLOYE_CONNEXION,  //lien pour le script de connexion d'utilisateur 

    LIEN_ADD_NEW_EMPLOYE,
    LIEN_UPDATE_EMPLOYE,
    LIEN_DELETE_EMPLOYE,

    LIEN_GET_EMPLOYE_ID,

} from "../providers/config_bdd";

import { BehaviorSubject } from 'rxjs';

export class AuthInfo {
    constructor(public $uid: string) { }

    isLoggedIn() {
        return !!this.$uid;
    }
}

@Injectable()
export class EmployesProvider {

    static UNKNOWN_USER = new AuthInfo(null);
    public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(EmployesProvider.UNKNOWN_USER);

    constructor(public http: HttpClient) {

    }

    //Récupérer tous les employés présent dans la base de données
    getAllEmployes(): any {
        return this.http.post(LIEN_GET_ALL_EMPLOYES
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            }
        );
    }

    /********************************************************
    * Connexion d'un employé avec son code personnel
   *********************************************************/
    getUtilisateurConnexion(codeemploye): any {
         let myData = JSON.stringify({
            codeemploye: codeemploye
        });
        return this.http.post(LIEN_GET_EMPLOYE_CONNEXION, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        });
    }

    /****************************************************************
     * Récupération d'un employé avec son identifiant : 
     ****************************************************************/
    getEmployeInfosId(idemploye): any{
        let myData = JSON.stringify({ idemploye : idemploye });
        return this.http.post(LIEN_GET_EMPLOYE_ID, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        });
    }

    
    /****************************************************************
     * Ajout d'un nouvel employé
     ****************************************************************/
    addNewEmploye(nomemploye, prenomemploye, codeemploye, isadmin): any {
        let myData = JSON.stringify({
            nomemploye: nomemploye,
            prenomemploye: prenomemploye,
            codeemploye: codeemploye,
            isadmin: isadmin
        });
        console.log("MyData Employe Provider Add new employe : " + myData);
        return this.http.post(LIEN_ADD_NEW_EMPLOYE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    /****************************************************************
     * Modification d'un employé
     ****************************************************************/
    updateEmploye(idemploye, nomemploye, prenomemploye, codeemploye, isadmin): any {
        let myData = JSON.stringify({
            idemploye : idemploye,
            nomemploye: nomemploye,
            prenomemploye: prenomemploye,
            codeemploye: codeemploye,
            isadmin: isadmin
        });
        console.log("MyData Employe Provider Update employe : " + myData);
        return this.http.post(LIEN_UPDATE_EMPLOYE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    /****************************************************************
     * Suppression d'un employé
    ****************************************************************/
    deleteEmploye(idemploye):any{
        let myData = JSON.stringify({
            idemploye : idemploye
        });
        return this.http.post(LIEN_DELETE_EMPLOYE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

}