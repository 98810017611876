export class Client{
    idclient: number;
    nomclient: string; 
    prenomclient: string;
    adresseclient: string; 
    npaclient: number;
    villeclient: string;
    paysclient: string; 
    telephoneclient: string;
    emailclient: string; 

    //login information : on ajout un mot de passe 
    motdepasseclient: string;
    is_active :number;


    constructor(idclient, nomclient, prenomclient, adresseclient, npaclient, villeclient, paysclient, telephoneclient, emailclient, motdepasseclient,is_active){
        this.idclient = idclient;
        this.nomclient = nomclient;
        this.prenomclient = prenomclient;
        this.adresseclient = adresseclient;
        this.npaclient = npaclient;
        this.villeclient = villeclient;
        this.paysclient = paysclient;
        this.telephoneclient = telephoneclient;
        this.emailclient = emailclient;
        this.motdepasseclient = motdepasseclient;
        this.is_active = is_active;
    }
}