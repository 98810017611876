/* Projet : Rolling Pizza - Genève
*  Script : Provider pour tout traitement des clients.
*  Auteur : Helvitech Dev Team 
*  Date : 25.06.2020
*  ------------------------
*  Description :  Provider qui va faire le traitement entre les fonction présents dans les ".ts" 
*                    et la base de données, dont les scripts sont défini dans "config_bdd.ts".
******************************************************************************************************/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

/*****************************************************************************
 * Importation des scripts des fonctions concernant le traitement de données *
 *  pour les commandes clients.
******************************************************************************/
import {
    //Récupère toutes les commandes clients d'un business connecté
    LIEN_GET_ALL_COMMANDES_CLIENTS_BUSINESS,
    LIEN_GET_ALL_COMMANDES_CLIENT_IDCLIENT,

    LIEN_GET_ALL_COMMANDES_PREPARATION,

    LIEN_GET_ALL_COMMANDES_NOUVELLES_WITH_PRODUCTS, //récupérer toutes les nouvelles commandes et leur produits
    LIEN_GET_ALL_COMMANDES_PREPARATION_WITH_PRODUCTS,// récupèrer toutes les commandes avec le détail des nom des produits
    LIEN_GET_ALL_COMMANDES_PRETARETIRER_WITH_PRODUCTS,
    LIEN_GET_ALL_COMMANDES_ENLIVRAISON_WITH_PRODUCTS,   //commandes et leurs produits - en livraison statut
    LIEN_GET_ALL_PRODUITS_COMMANDES, //récupérer les produits d'une commande
    LIEN_GET_NAME_PRODUITS_COMMANDE, //récupérer les noms de produits d'une commande
    LIEN_ADD_NEW_COMMANDE,//ajouter une nouvelle commande
    LIEN_ADD_NEW_COMMANDE_PARTENAIRE,//jouter une nouvelle commande du partenaire
    LIEN_DELETE_COMMANDE_ID,
    /****Update scripts *****/
    LIEN_UPDATE_COMMANDE_TO_ENPREPARATION,
    LIEN_UPDATE_COMMANDE_ISPAID_STATUT,
    LIEN_UPDATE_COMMANDE_STATUT,
    LIEN_GET_ALL_COMMANDES_PAYEES,
    LIEN_GET_ALL_COMMANDES_NON_PAYEES,
    LIEN_GET_ALL_COMMANDES_NON_TRAITEES,
    LIEN_GET_ALL_COMMANDES_DELETED,
    LIEN_GET_HORAIRES_SHOP,// récupère les horaires du shop
    LIEN_GET_SHOP_ID
} from "../providers/config_bdd";

@Injectable()
export class CommandesClientProvider {
 
    constructor(
        public http: HttpClient
    ) { }

    /******* */
    public data$: BehaviorSubject<any> = new BehaviorSubject({});

    /*updateDatas() {
        let data = this.http.get('http://www.data.com').map((data)=>{
            return data.json();
        }).do((data)=>{
            this.data$.next(data);
        })
    }*/
    updateData(): Observable<any>  {
        return this.getData().do((data) => {
            this.data$.next(data);
        });
    }
    getData(): any {
        var myData = JSON.stringify({ idshop : '1' });
        this.http.post(LIEN_GET_ALL_COMMANDES_NOUVELLES_WITH_PRODUCTS, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

   /* private dataSubject: BehaviorSubject<[]> = new BehaviorSubject([]);
    public data$a: BehaviorSubject<any> = new BehaviorSubject({});

    data$: Observable<[]> = this.dataSubject.asObservable();

    updateDatas(): Observable<any>  {
        return this.getData().do((data) => {
            this.data$.next(data);
        });
    }
   
    

    getDatas(): Observable<[]> {
        var myData = JSON.stringify({ idshop : '1' });
         this.http.post(LIEN_GET_ALL_COMMANDES_NOUVELLES_WITH_PRODUCTS, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        }).subscribe(res=>{
            return Observable<[]>res;
        });
    }*/
   
    /****** */
    //Récupérer toutes les commandes clients du business connecté
    getAllCommandesClientsBusiness(idshop): any {
        var myData = JSON.stringify({ idshop: idshop });
        return this.http.post(LIEN_GET_ALL_COMMANDES_CLIENTS_BUSINESS, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        });
    }

    //Récupérer toutes les commandes d'un client spécifique, avec son identifiant : 
    getAllCommandesClientIdClient(idclient): any {
        var myData = JSON.stringify({ idclient: idclient });
        console.log("PROVIDER _ MYDATA _ PRESENT : " +myData);
        return this.http.post(LIEN_GET_ALL_COMMANDES_CLIENT_IDCLIENT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        });
    }

    /********************************************************
     * Fonctions pour récupérer les produits d'une commande *
     ********************************************************/
    getAllProduitCommande(idcom):any{
        var myData = JSON.stringify({ idcom : idcom });
        return this.http.post(LIEN_GET_ALL_PRODUITS_COMMANDES, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        });
    }

    //Récupérer le nom des produit d'une commande
    getNameProduitsCommande(idproduit):any{
        var myData = JSON.stringify({ idproduit : idproduit });
        return this.http.post(LIEN_GET_NAME_PRODUITS_COMMANDE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }


    /************************************************************
     * Fonctions pour récupérer les commandes selon leur statut *
     ************************************************************/
    getAllCommandesPreparation(): any {
        return this.http.post(LIEN_GET_ALL_COMMANDES_PREPARATION, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupère toutes les novuelles commandes avec leurs nom des produits
    getAllCommandesNouvelleWithProduits(): Observable<any> {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_NOUVELLES_WITH_PRODUCTS, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupère toutes les commandes avec leurs nom des produits - en préparation
    getAllCommandesPreparationWithProduits(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_PREPARATION_WITH_PRODUCTS, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupère toutes les commandes avec leurs nom des produits - a retirer
    getAllCommandesPretARetirerWithProduits(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_PRETARETIRER_WITH_PRODUCTS, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupère toutes les commandes avec leurs nom des produits - en livraison 
    getAllCommandesEnLivraisonWithProduits(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_ENLIVRAISON_WITH_PRODUCTS, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //récupère toutes les commandes payees 
    getAllCommandesPayees(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_PAYEES, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //récupère toutes les commandes non payees 
    getAllCommandesNonPayees(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_NON_PAYEES, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //récupère toutes les commandes non payees 
    getAllCommandesNonTraitees(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_NON_TRAITEES, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //récupère toutes les commandes archivées 
    getAllCommandesDeleted(): any {
        var myData = JSON.stringify({ idshop : '1' });
        return this.http.post(LIEN_GET_ALL_COMMANDES_DELETED, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    
    /*****************************************************************
     * Mise à jour du statut des commandes 
     *****************************************************************/
    updateCommandeToEnPreparation(idcom):any{
        var myData = JSON.stringify({idcom : idcom});
        return this.http.post(LIEN_UPDATE_COMMANDE_TO_ENPREPARATION, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    updateStatutCommande(idcom,statut){
        var myData = JSON.stringify(
            {idcom : idcom,
            statut:statut

        });
        return this.http.post(LIEN_UPDATE_COMMANDE_STATUT, myData,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
         //add new commande
         addPanier(panier):any{
            var myData = JSON.stringify({panier : panier});
            alert("Panier particulier avant insert :"+myData);
            return this.http.post(LIEN_ADD_NEW_COMMANDE, myData,{
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            })  
        }

     
        deleteCommandeId(idcom): any {
            var myData = JSON.stringify({ idcom: idcom });
            return this.http.post(LIEN_DELETE_COMMANDE_ID, myData, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            })
        }
        updateCommandeIsPaidStatut(idcom,statutPaid):any{
            var myData = JSON.stringify(
                {idcom : idcom,
                statutPaid : statutPaid

            });
            return this.http.post(LIEN_UPDATE_COMMANDE_ISPAID_STATUT, myData,{
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            })
        }
        //Horaires Shop
getHorairesShop(): any {
    var myData = JSON.stringify({ idshop: '1' });
    return this.http.post(LIEN_GET_HORAIRES_SHOP, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}
  //get a shop  id
  getShopID(idshop): any {
    var myData = JSON.stringify({ idshop: idshop });
    return this.http.post(LIEN_GET_SHOP_ID, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}
}