import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Client } from 'src/models/client_model';
import { ClientProvider } from 'src/providers/clients_provider';

@Component({
  selector: 'app-modifierclient',
  templateUrl: './modifierclient.page.html',
  styleUrls: ['./modifierclient.page.scss'],
})
export class ModifierclientPage implements OnInit {

  client: Client;
  clientUpdate: Client;
  clientTempo: Client;
  toggleValue: boolean = false;
  constructor(
    private router: Router,
    private modalCtrl : ModalController,
    private clientProvider: ClientProvider ,public navParams: NavParams
  ) { 
    
    //this.client = new Client (null, null, null, null, null, null, null, null, null, null);
    this.clientUpdate = new Client (null, null, null, null, null, null, null, null, null, null,null);
    this.clientUpdate =this.client;
    this.clientTempo = this.navParams.get('client');

  

    //console.log("this.clientUpdate :" + JSON.stringify(this.clientUpdate.nomclient));
   // console.log("this.client :" +JSON.stringify(this.client.nomclient));

  }

  ngOnInit() {
    /*this.clientProvider.getUtilisateurConnexion(this.client.emailclient,this.client.motdepasseclient).subscribe(
      res => {
        this.clientTempo = res;    
        console.log("res : ", res);}
    );*/
    if(this.clientTempo.is_active==0){ 
    
      this.toggleValue =false;}
    else{
   
      this.toggleValue =true;}
  
  }

  update(){
    console.log("nomclient after update :" +this.clientTempo.nomclient);
 

    if(this.toggleValue==false){  this.clientTempo.is_active=0;}else{this.clientTempo.is_active=1;}


    this.clientProvider.updateProfilClient(
      this.clientTempo.idclient, this.clientTempo.nomclient, this.clientTempo.prenomclient,
      this.clientTempo.adresseclient, this.clientTempo.npaclient, this.clientTempo.villeclient,
      this.clientTempo.paysclient, this.clientTempo.telephoneclient, this.clientTempo.emailclient, this.clientTempo.is_active
    ).subscribe(res => {
      if (res == false) {
        alert("ERREUR" + res);
        //console.log("ERROR : " + res)

      } else {
      
      
        this.annuler();
        //router navigation 
      
      }
    });

  }

  annuler(){
  this.modalCtrl.dismiss();
   
 //   this.router.navigate(['../listeclients']);
  
/*    let navigationExtras : NavigationExtras = {
      state : {
        client : this.clientTempo
      }
    };
    this.router.navigate(['/listeclient']);*/
  }

}