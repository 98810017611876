/* Projet : Rolling Pizza - Genève
*  Script : Provider pour tout traitement des produits.
*  Auteur : Helvitech Dev Team 
*  Date : 01.06.2020
*  ------------------------
*  Description :  Provider qui va faire le traitement entre les fonction présents dans les ".ts" 
*                    et la base de données, dont les scripts sont défini dans "config_bdd.ts".
******************************************************************************************************/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

/*****************************************************************************
 * Importation des scripts des fonctions concernant le traitement de données *
 *  pour les produits.
******************************************************************************/
import {
    LIEN_GET_ALL_PRODUITS,
    LIEN_GET_ALL_PRODUITS_BUSINESS,
    LIEN_GET_PRODUIT,
    LIEN_GET_DETAIL_COMPOSANTS_PRODUIT,
    LIEN_DELETE_COMPOSANT_PRODUIT,

    LIEN_ADD_NEW_PRODUIT,   //ajout d'un nouveau produit
    LIEN_DELETE_PRODUIT,
    LIEN_UPLOAD_FILES,

    LIEN_GET_ALL_CATEGORIE_PRODUIT, //récupérer toutes les categories de produits
    LIEN_GET_ALL_TYPE_PRODUITS,
    LIEN_GET_TYPE_PRODUIT,
    LIEN_ADD_NEW_TYPEPRODUIT,
    LIEN_DELETE_TYPEPRODUIT,
    LIEN_UPDATE_TYPEPRODUIT,
    LIEN_UPDATE_PRODUIT,
    LIEN_GET_SHOPS_ID_PRODUIT,

    LIEN_GET_ALL_CATEGORIE_PRODUITS,
    LIEN_GET_ALL_SOUS_CATEGORIE_PRODUITS,
    LIEN_DELETE_CATEGORIE,
    LIEN_ADD_NEW_CATEGORIE,
    LIEN_GET_CATEGORIE,
    LIEN_UPDATE_CATEGORIE,
    LIEN_ALL_TYPE_PRODUITS_ID_CATEGORIE,
    LIEN_GET_ALL_PRIX_TAILLE_PIZZA,//récupère le rpix  et la taille des prix sur place / à emporter
    LIEN_GET_ALL_PRIX_TAILLE_IDPIZZA_LIVRAISON,//récupère les prix et les tailles d'une pizza selon son id en livraison

    //récupérer la liste des types de produits, selon le nom du type de shop du business connecté
    LIEN_GET_TYPE_PRODUITS_BY_BUSINESS_ID_TYPE_SHOP,
    //Récupérer les informations du type de shop du business connecté : 
    LIEN_GET_TYPE_SHOP_BUSINESS_CONNECTED,
    //Récupérer les catégorie du type de produit du type de shop du business connecté 
    LIEN_GET_CATEGORIE_TYPE_SHOP_BUSINESS_CONNECTED,
    LIEN_GET_ALL_TYPE_LIVRAISON,
    LIEN_GET_TYPEPRODUIT_PIZZA_PRODUITS,
    LIEN_GET_PRIX_PRODUIT_TAILLE_LIVRAISON,
    LIEN_GET_ALL_OFFRES,
    LIEN_SEND_EMAIL_CLIENTS_OFFERT
} from "../providers/config_bdd";
import { Observable } from 'rxjs';


@Injectable()
export class ProduitProvider {
    constructor(public http: HttpClient) {

    }

    //Récupérer tous les shops/business présent dans la base de données
    getAllProduits(): any {
        return this.http.post(LIEN_GET_ALL_PRODUITS
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            }
        );
    }
  //Récupérer tous les shops/business présent dans la base de données
  getAllOffres(): any {
    return this.http.post(LIEN_GET_ALL_OFFRES
        , {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        }
    );
}
    //Récupérer les produits d'un business connecté; selon son identifiant
    getAllProduitsBusiness(idshop): any {
        var myData = JSON.stringify({ idshop: idshop });
        return this.http.post(LIEN_GET_ALL_PRODUITS_BUSINESS, myData
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            });
    }
    //Récupérer un produit selon son id
    getProduit(idproduit):any{
        let myData = JSON.stringify({
            idproduit: idproduit            
        });
       
        return this.http.post(LIEN_GET_PRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        }) 
    }

      //récupérer laliste des ingredients delon le id d'un produits
      getListeIngredientProduit(idproduit){
        var myData = JSON.stringify({ idproduit: idproduit });
        return this.http.post(LIEN_GET_DETAIL_COMPOSANTS_PRODUIT, myData
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            });
    }
      //récupérer la liste des shops ou le produit existe
      getListeShopsProduitDisponible(idproduit){
        var myData = JSON.stringify({ idproduit: idproduit });
        return this.http.post(LIEN_GET_SHOPS_ID_PRODUIT, myData
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            });
    }
   
    //supprimer un composant d'un produit donné selon son id
    deleteIngredientProduitID(idproduit,idcomposant){
        let myData = JSON.stringify({
            idproduit : idproduit ,  
            idcomposant :idcomposant    
        });
       
        return this.http.post(LIEN_DELETE_COMPOSANT_PRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })  
    }
    //Ajout d'un produit par un business connecté 
    addNewProduit(nomproduit, prixproduit, idtypeproduit, idcategorie,listeprix ,allIngredientsProduit,detailsCombos,detailsOffres): any {
        let myData = JSON.stringify({
            nomproduit: nomproduit,
            prixproduit: prixproduit,
            idtypeproduit: idtypeproduit,
            idcategorie: idcategorie,
            listeprix : listeprix,
            allIngredientsProduit :allIngredientsProduit,
            detailsCombos: detailsCombos,
            detailsOffres:detailsOffres
        });
        console.log("MyData Produit Provider Add new produit : " + myData);
        return this.http.post(LIEN_ADD_NEW_PRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //Supprimer un produit selin son id
    deleteProduit(idproduit,imageproduit){
        let myData = JSON.stringify({
            idproduit : idproduit ,  
            name_photo :imageproduit    
        });
       
        return this.http.post(LIEN_DELETE_PRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })  
    }

    //update produit
    updateProduitId(idproduit,nomproduit, prixproduit,idcategorie, idtypeproduit,allIngredientsProduit,listeprix,detailsCombos,detailsOffres,is_disponible,allShopsProduitDisponible):any{
        let myData = JSON.stringify({
            idproduit            : idproduit,
            nomproduit           : nomproduit,
            prixproduit          : prixproduit,
            idcategorie          : idcategorie,           
            idtypeproduit        : idtypeproduit,
            allIngredientsProduit: allIngredientsProduit,
            listeprix            : listeprix,
            detailsCombos        : detailsCombos,
            detailsOffres        : detailsOffres,
            is_disponible        : is_disponible,
            allShopsProduitDisponible:allShopsProduitDisponible          
        });
        //alert(myData);
        return this.http.post(LIEN_UPDATE_PRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
       

        }
    //Ajouter un nouveau type de produit : 
    addNewTypeProduit(nomtypeproduit, idcategorie){
        let myData = JSON.stringify({
            nomtypeproduit : nomtypeproduit,
            idcategorie : idcategorie
        });
        return this.http.post(LIEN_ADD_NEW_TYPEPRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
  
    //Supprimer un type de produit selon son id: 
    deleteTypeProduit(idtypeproduit){
    let myData = JSON.stringify({
        idtypeproduit : idtypeproduit       
    });
   
    return this.http.post(LIEN_DELETE_TYPEPRODUIT, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}

    //update un type de produit selon son id 
    updateTypeProduit(idtypeproduit,nomtypeproduit,idcategorieproduit):any{
    let myData = JSON.stringify({
        idtypeproduit : idtypeproduit,
        nomtypeproduit : nomtypeproduit,
        idcategorieproduit : idcategorieproduit
    });
   // alert(myData);
    return this.http.post(LIEN_UPDATE_TYPEPRODUIT, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}
    /***********************************************************
     * Pour récupérer tous les types et catégories de produits *
     ***********************************************************/
    //liste des types de produits : 
     getAllTypeProduits(): any {
        return this.http.post(LIEN_GET_ALL_TYPE_PRODUITS, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupère un type produit selon son id  : 
     getTypeProduitId(idtypeproduit): any {
        let myData = JSON.stringify({
            idtypeproduit : idtypeproduit
        });
      
        return this.http.post(LIEN_GET_TYPE_PRODUIT,myData ,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupérer les types produits appartenent à une seule catégorie
    getAllTypeProduitsIdCategorie(idcategorie):any{
        let myData = JSON.stringify({
            idcategorie : idcategorie
        });
        return this.http.post(LIEN_ALL_TYPE_PRODUITS_ID_CATEGORIE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //liste des catégories de produits : 
    getAllCategorieProduits() : any{
        return this.http.post(LIEN_GET_ALL_CATEGORIE_PRODUITS, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //récupérer une catégorie selon son id
    getCategorieId(idcategorie):any{
        var myData = JSON.stringify({ idcategorie: idcategorie });
        return this.http.post(LIEN_GET_CATEGORIE, myData
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            });
    }
    //Ajouter une nouvelle catégorie
    addNewCategorie(newCategorie){
        let myData = JSON.stringify({
            newCategorie : newCategorie            
        });
        return this.http.post(LIEN_ADD_NEW_CATEGORIE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //delete une catégorie
    deleteCategorie(idcategorieproduit){
        let myData = JSON.stringify({
            idcategorieproduit : idcategorieproduit       
        });
       
        return this.http.post(LIEN_DELETE_CATEGORIE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        }) 
    }    
    //update une catégorie selon son id
    updateCategorieId( idcategorie, nomcategorie):any{
        let myData = JSON.stringify({
            idcategorie : idcategorie,
            nomcategorie : nomcategorie
        });
       // alert(myData);
        return this.http.post(LIEN_UPDATE_CATEGORIE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //liste des sous-catégories de produits : 
    getAllSousCategorieProduits(): any{
        return this.http.post( LIEN_GET_ALL_SOUS_CATEGORIE_PRODUITS, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //-------------------------------------------------------------------------------**
    //Récupérer la liste des type de produits, selon le type de business connecté
    getTypeProduitByBusinessIdTypeShop(idtypeshop):any{
        let myData = JSON.stringify({
            idtypeshop : idtypeshop
        });
        return this.http.post(LIEN_GET_TYPE_PRODUITS_BY_BUSINESS_ID_TYPE_SHOP, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //Récupérer les informations du typeShop du business connecté : 
    getTypeShopBusinessConnected(nomtypeshop):any{
        let myData = JSON.stringify({
            nomtypeshop : nomtypeshop
        });
        return this.http.post(LIEN_GET_TYPE_SHOP_BUSINESS_CONNECTED, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //Récupérer les catégorie de produit du type de produit du business connecté : 
    getCategorieProduitTypeProduit(idtypeproduit): any{
        let myData = JSON.stringify({
            idtypeproduit : idtypeproduit
        });
        return this.http.post(LIEN_GET_CATEGORIE_TYPE_SHOP_BUSINESS_CONNECTED, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
//UPLOAD PHOTO
uploadImage(image,id) {   
//alert("image upload :"+image);
    var myData = JSON.stringify(id);
   // image.forEach(element => {
      const postData = new FormData();

      postData.append('base64_file', image);
      postData.append('base64_produit', myData);

      //let dt: Observable<any> = this.http.post(url, postData);
      let dt: Observable<any> = this.http.post(LIEN_UPLOAD_FILES, postData);
      dt.subscribe((result) => {
        console.log("result de l'upload: ", result);
      },err =>{
        console.log("erreur resultat de upload "+err);
    //  });
    });

  }

  //PRIX PIZZA NON livraison
  getAllPrixTaillePizza():any{
    return this.http.post( LIEN_GET_ALL_PRIX_TAILLE_PIZZA, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
  }
  getAllPrixTaillePizzaLivraison(idproduit):any{
    let myData = JSON.stringify({
        idproduit : idproduit
    });
    return this.http.post(LIEN_GET_ALL_PRIX_TAILLE_IDPIZZA_LIVRAISON, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
  }
    //liste des sous-catégories de produits : 
    getAllTypesLivraison(): any{
        return this.http.post( LIEN_GET_ALL_TYPE_LIVRAISON, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    /*****************************************
     * Récupérer les produits selon son type *
     *****************************************/
    //Pizza : 
    getTypePizzaProduits(idtypeproduit):any{
        let myData = JSON.stringify({
            idtypeproduit : idtypeproduit
        })
        return this.http.post(LIEN_GET_TYPEPRODUIT_PIZZA_PRODUITS, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //récupérer lée prix d'un produit selon le type de livraison
    getPrixTaillePizza(idproduit,taille,idtypelivraison):any{
        let myData = JSON.stringify({
            idproduit : idproduit,
            taille : taille,
            idtypelivraison : idtypelivraison
        })      
        return this.http.post(LIEN_GET_PRIX_PRODUIT_TAILLE_LIVRAISON, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //Envoyer un email au client avec le résumé de la commande 
sendEmailClientsOffert(idproduit):any{
    var myData = JSON.stringify(
        {idproduit : idproduit
    });
    return this.http.post(LIEN_SEND_EMAIL_CLIENTS_OFFERT, myData,{
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
  
        })
    }

}
