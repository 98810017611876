/* Projet : Rolling Pizza - Genève
*  Script : Provider pour tout composant et ingredient des produit.
*  Auteur : Helvitech Dev Team 
*  Date : 25.06.2020
*  ------------------------
*  Description :  Provider qui va faire le traitement entre les fonction présents dans les ".ts" 
*                    et la base de données, dont les scripts sont défini dans "config_bdd.ts".
******************************************************************************************************/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

/*****************************************************************************
 * Importation des scripts des fonctions concernant le traitement de données *
 *  pour composants et les ingredients des produits.
******************************************************************************/
import { 
  
    LIEN_GET_ALL_COMPOSANTS,
    LIEN_GET_ALL_CATEGORIES_COMPOSANTS,
    LIEN_GET_DETAIL_COMPOSANT,
    LIEN_ADD_NEW_COMPOSANT,
    LIEN_UPDATE_COMPOSANT,
    LIEN_DELETE_COMPOSANT,
    LIEN_GET_ALL_PRIX_GARNITURES_SUPPLEMENTAIRES
} from "../providers/config_bdd";


@Injectable()
export class ComposantPizzaProvider{
    constructor(public http: HttpClient){

    }

    //Récupérer tous les shops/business présent dans la base de données
    getAllComposants(): any{
        return this.http.post(LIEN_GET_ALL_COMPOSANTS
            , {
                headers: new HttpHeaders()
                .set('Content-Type','application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin','*')
            }
        );
    }
    //récupérer tous les catégories des composants
    getAllCategorieComposant():any{
        return this.http.post(LIEN_GET_ALL_CATEGORIES_COMPOSANTS
            , {
                headers: new HttpHeaders()
                .set('Content-Type','application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin','*')
            }
        );
    }
  
    //récupérer le détail d'un composant selon son id
    getDetailComposant(idcomposant):any{
        var myData = JSON.stringify({ idcomposant: idcomposant });
        return this.http.post(LIEN_GET_DETAIL_COMPOSANT, myData
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            });
    }
    //add new composant 
    addNewComposant(nomcomposant,prixcomposant,categoriecomposant)
    {
        let myData = JSON.stringify({
            nomcomposant : nomcomposant,
            prixcomposant : prixcomposant,
            categoriecomposant: categoriecomposant            
        });
        alert(myData);
        return this.http.post(LIEN_ADD_NEW_COMPOSANT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
      }
      //Update un composant selon son id
      updateComposant(idcomposant,nomcomposant, categoriecomposant, prixcomposant){
        let myData = JSON.stringify({
            idcomposant : idcomposant,
            nomcomposant : nomcomposant,
            categoriecomposant : categoriecomposant,
            prixcomposant : prixcomposant,

        });
        alert(myData);
        return this.http.post(LIEN_UPDATE_COMPOSANT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
      }
      //supprimer un composant selon son id
      deleteComposant(idcomposant){
        let myData = JSON.stringify({
            idcomposant : idcomposant       
        });
       
        return this.http.post(LIEN_DELETE_COMPOSANT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })  
      }
         //récupérer la liste des prix des garnitures supplémentaires
         getPrixGarnituresSupplementaire():any{
            return this.http.post(LIEN_GET_ALL_PRIX_GARNITURES_SUPPLEMENTAIRES
                , {
                    headers: new HttpHeaders()
                    .set('Content-Type','application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin','*')
                }
            );
          }

}